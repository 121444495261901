/**
 * Liste des pages accessibles depuis le vue home de DGAuth
 */
import { defines } from "../../router/defines.js";
import { hasOneOfRole } from "../../service/role_service.js";


export const tableListDgauth = [
  {
    // Les rôles des services
    name: "Services",
    route: defines.dgauthservices.view.path,
    roles: [ defines.dgauthservices.view.roles, ], 
  },
  {
    // Les rôles des fonctions
    name: "Fonctions",
    route: defines.dgauthfunctions.view.path, 
    roles: [ defines.dgauthfunctions.view.roles, ],
  },
  {
    // Les rôles par collaborateurs (choix du collaborateur first)
    name: "Collaborateurs",
    route: defines.dgauthusers.view.path,
    roles: [ defines.dgauthusers.view.roles, ],
  },
  {
    // Les rôles de tous les clients
    name: "Clients",
    route: defines.dgauthcustomerroles.view.path,
    roles: [ defines.dgauthcustomerroles.view.roles, ],
  },
  {
    // Les rôles de tous les collaborateurs ayant de rôles spécifiques
    name: "Roles spécifiques collaborateurs",
    route: defines.dgauthusersroles.view.path,
    roles: [ defines.dgauthusersroles.view.roles, ],
  },
]


/**
 * Retourne la liste des pages consultables pour Dgauth 
 * en fonction des droits applicatifs de l'utilisateur
 */
export function getTableList() {
  let table = [];

  // Parcours les tables a affiché
  for (let item of tableListDgauth) {
    // Vérifie que l'utilisateur as le droit d'accéder à cette page.
    if (hasOneOfRole(item.roles)) {
      table.push(item);
    }
  }

  return table;
}